import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { GroupDto } from "../../../../app/model/api";

interface AddGroupModalProps {
  open: boolean;
  onClose: () => void;
  parentGroup?: GroupDto;
  onSave: (name: string, defaultPool: string) => void;
}

interface FormInputs {
  name: string;
  defaultPool: string;
}

export const AddGroupModal = ({ open, onClose, parentGroup, onSave }: AddGroupModalProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormInputs>({
    defaultValues: {
      name: "",
      defaultPool: "",
    },
  });

  const onSubmit = (data: FormInputs) => {
    onSave(data.name, data.defaultPool);
    reset();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{parentGroup ? "Add Subgroup" : "Add Group"}</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Box display="flex" flexDirection="column" gap={2} mt={1}>
            <TextField
              label="Group Name"
              fullWidth
              autoFocus
              required
              error={!!errors.name}
              helperText={errors.name?.message}
              {...register("name", { required: "Group name is required" })}
            />
            <TextField
              label="Default Pool"
              fullWidth
              required
              error={!!errors.defaultPool}
              helperText={
                errors.defaultPool?.message ||
                "Include username and password in URL if required (e.g. stratum+tcp://user:pass@pool.example.com:3333)"
              }
              placeholder="stratum+tcp://user:pass@pool.example.com:3333"
              {...register("defaultPool", { required: "Default pool is required" })}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit" variant="contained" color="primary">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
