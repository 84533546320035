import { Chip } from "@mui/material";
import { useGetMiningProxyContractStatus } from "../../../../app/query/useQueryGetAdmin";

interface ContractStatusProps {
  uuid: string;
}

export const ContractStatus = ({ uuid }: ContractStatusProps) => {
  const { data: status, isLoading, error } = useGetMiningProxyContractStatus(uuid);

  if (isLoading) {
    return <Chip size="small" label="Loading..." sx={{ width: 100 }} />;
  }

  if (error || !status) {
    return <Chip size="small" label="Error" color="error" sx={{ width: 100 }} />;
  }

  const label = status?.status ? status.status.charAt(0).toUpperCase() + status.status.slice(1) : "Unknown";

  const chipColor = status?.status === "Online" ? "success" : "error";

  return <Chip size="small" label={label} color={chipColor} sx={{ width: 100 }} />;
};
