import * as yup from "yup";
import { MINER_GRANULAR_TIME_HORIZON_OF_LIQUIDITY } from "../../app/constants";
import { RegisterMinerDto, RegisterMinerDtoTimeHorizonOfLiquidityEnum } from "../../app/model/api";
import {
  powerOfAttorneyStringToEnum,
  primaryContactStringToEnum,
  YUP_COMMON,
  YUP_REQUIRED_POSITIVE_INTEGER,
  YUP_REQUIRED_URL,
} from "./common.schema";

const YUP_ANNUAL_REPORT_URL = yup.string().when("isPubliclyListed", {
  is: true,
  then: () => YUP_REQUIRED_URL,
  otherwise: () =>
    yup.string().test("is-equal", "Invalid value provided", function (input: string | undefined) {
      return input == undefined;
    }),
});

export const YupMinerFormSchema = yup
  .object({
    ...YUP_COMMON,
    annualReportUrl: YUP_ANNUAL_REPORT_URL,
    primaryEnergySource: yup.string().optional().nullable(),
    selfMiningCapacity: YUP_REQUIRED_POSITIVE_INTEGER,
    averageEnergyPrice: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable()
      .notRequired(),
  })
  .required();

export type MinerOnboardingForm = yup.InferType<typeof YupMinerFormSchema>;

export const createRegisterMinerDto = (input: MinerOnboardingForm): RegisterMinerDto => {
  // we default to empty values here because the api will validate it anyway,
  const result: RegisterMinerDto = {
    user: {
      firstName: input.currentUserfirstname ?? "",
      lastName: input.currentUserlastname ?? "",
      email: input.currentUseremail ?? "",
      cellphone: input.currentUserphone ?? "",
    },
    primaryContact: {
      primaryContactKind: primaryContactStringToEnum(input.primaryContact),
      hasPowerOfAttorney: powerOfAttorneyStringToEnum(input.powerOfAttorney),
      reference:
        input.primaryContact === "another-person"
          ? {
              firstName: input.anotherUserfirstname ?? "",
              lastName: input.anotherUserlastname ?? "",
              email: input.anotherUseremail ?? "",
              cellphone: input.anotherUserphone ?? "",
            }
          : undefined,
    },
    annualReportUrl: input.isPubliclyListed ? input.annualReportUrl || undefined : undefined,
    companyName: input.companyLegalName ?? "",
    companyRole: input.roleAtCompany ?? "",
    homepageUrl: input.homepageUrl || undefined,
    miningCapacityPhPerSecond: input.selfMiningCapacity,
    averageEnergyPriceUsdPerMWh: input.averageEnergyPrice || undefined,
    primaryEnergySource: input.primaryEnergySource || undefined,
    companyCountryOfIncorporation: input.countryOfIncorporation ?? "",
    isPubliclyListed: input.isPubliclyListed,
    timeHorizonOfLiquidity:
      MINER_GRANULAR_TIME_HORIZON_OF_LIQUIDITY.find((x) => x.value === input.timeHorizon)?.id ??
      RegisterMinerDtoTimeHorizonOfLiquidityEnum.Notsure,
  };

  return result;
};
