import { Box, Grid, Step, StepConnector, StepLabel, Stepper, Typography, keyframes, styled } from "@mui/material";
import { getStageFromStep, isStepCompleted } from "../constants";
import { STAGE_STEPS, STEP_METADATA, StageType, isInProgressStep } from "../types";
import { ContinuousAction } from "./ContinuousAction";
import { OpportunityProgressDto, OpportunityProgressDtoCurrentStageEnum } from "../../../../app/model/api";

const pulseAnimation = keyframes`
  0% {
    transform: scale(0.95);
    opacity: 0.9;
  }
  100% {
    transform: scale(3);
    opacity: 0;
  }
`;

export const CustomStepConnector = styled(StepConnector)<{
  isCurrentStage: boolean;
}>(({ theme, isCurrentStage }) => ({
  "&.MuiStepConnector-root": {
    marginLeft: 0,
  },
  "& .MuiStepConnector-line": {
    minHeight: 24,
    borderLeftWidth: 1,
  },
  "&.Mui-completed .MuiStepConnector-line": {
    borderColor: isCurrentStage ? theme.palette.primary.main : theme.palette.divider,
  },
}));

export const CustomStepIcon = styled("div")<{
  ownerState: {
    isCurrentStage: boolean;
    isContinuousAction: boolean;
    stepState: "completed" | "current" | "warning" | "error" | "future";
  };
}>(({ theme, ownerState }) => {
  const getColor = () => {
    switch (ownerState.stepState) {
      case "completed":
        return ownerState.isCurrentStage ? theme.palette.primary.main : theme.palette.text.disabled;
      case "current":
        return theme.palette.primary.main;
      case "warning":
        return theme.palette.warning.main;
      case "error":
        return theme.palette.error.main;
      case "future":
        return theme.palette.divider;
    }
  };

  const isSolid =
    ownerState.stepState === "completed" ||
    ownerState.stepState === "warning" ||
    ownerState.stepState === "error" ||
    (ownerState.stepState === "current" && ownerState.isContinuousAction);
  const shouldPulse =
    ownerState.stepState === "current" || ownerState.stepState === "warning" || ownerState.stepState === "error";

  return {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: isSolid ? getColor() : "transparent",
    border: !isSolid ? `1.5px solid ${getColor()}` : "none",
    position: "relative",
    ...(shouldPulse && {
      "&::before": {
        content: '""',
        position: "absolute",
        top: -2,
        left: -2,
        right: -2,
        bottom: -2,
        borderRadius: "50%",
        border: `1.5px solid ${getColor()}`,
        animation: `${pulseAnimation} 2s infinite`,
      },
    }),
  };
});

interface StageContentProps {
  data: OpportunityProgressDto;
  type: StageType;
}

export const StageContent = ({ data, type }: StageContentProps) => {
  const isCurrentStage = isInProgressStep(data.currentStage) && type === getStageFromStep(data.currentStage);

  // For ACTIVE stage, always show the continuous action
  if (type === "ACTIVE") {
    return (
      <Grid item xs={4} sx={{ display: "flex", justifyContent: "center" }}>
        <Box>
          <ContinuousAction
            completed={isStepCompleted(
              OpportunityProgressDtoCurrentStageEnum.DELIVER_SUFFICIENT_REWARDS,
              data.currentStage
            )}
            current={isCurrentStage}
            collateralStatus={data.collateralStatus || "Sufficient"}
            lastRewardAt={data.lastRewardAt ? new Date(data.lastRewardAt) : null}
          />
        </Box>
      </Grid>
    );
  }

  const stageSteps = STAGE_STEPS[type];
  const currentStepIndex = isCurrentStage ? stageSteps.findIndex((step) => step === data.currentStage) : -1;

  return (
    <Grid item xs={4} sx={{ display: "flex", justifyContent: "center" }}>
      <Box>
        <Stepper
          activeStep={currentStepIndex}
          orientation="vertical"
          connector={<CustomStepConnector isCurrentStage={isCurrentStage} />}
          sx={{
            mt: 1,
            mb: 1,
            "& .MuiStepLabel-root": {
              p: 0,
              ml: -0.5,
            },
            "& .MuiStepLabel-labelContainer": {
              ml: 1,
            },
            "& .MuiStep-root": {
              mb: -1,
              mt: -1,
            },
          }}
        >
          {stageSteps.map((step, idx) => {
            const stepMetadata = STEP_METADATA[step];
            return (
              <Step key={idx} completed={isStepCompleted(step, data.currentStage)}>
                <StepLabel
                  StepIconComponent={() => (
                    <CustomStepIcon
                      ownerState={{
                        isCurrentStage,
                        isContinuousAction: false,
                        stepState: isStepCompleted(step, data.currentStage)
                          ? "completed"
                          : isCurrentStage && idx === currentStepIndex
                          ? "current"
                          : "future",
                      }}
                    />
                  )}
                >
                  <Typography variant="body2" color={isCurrentStage ? "text.primary" : "text.secondary"}>
                    {stepMetadata.label}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {stepMetadata.party}
                  </Typography>
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Box>
    </Grid>
  );
};
