import * as yup from "yup";

const WHITELIST_SCHEMA = yup.object().shape({
  walletName: yup.string().required("This field is required"),
  walletAddress: yup.string().required("This field is required"),
});

const WITHDRAW_REQUEST_SCHEMA = yup.object().shape({
  amount: yup
    .number()
    .nullable()
    .transform((_, value) => {
      return value === "" ? null : value ? Number(value) : null;
    })
    .min(0.000001)
    .required("This field is required"),
  address: yup.string().required("This field is required"),
});

const BROADCAST_NOTIFICATION_SCHEMA = yup.object().shape({
  title: yup.string().required("This field is required"),
  priority: yup.string().required("This field is required"),
  description: yup.string().required("This field is required"),
  actionable: yup.string().required("This field is required"),
  receiver: yup.string().required("This field is required"),
  link: yup
    .string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Enter correct url!"
    )
    .when("actionable", {
      is: "Yes",
      then: () => yup.string().required("This field is required"),
      otherwise: () => yup.string().notRequired(),
    }),
});

const INVITE_ORGANIZATION_USER_SCHEMA = yup.object().shape({
  email: yup.string().email("Email is not a valid").required("This field is required"),
  firstName: yup.string().required("This field is required"),
  lastName: yup.string().required("This field is required"),
});

export { BROADCAST_NOTIFICATION_SCHEMA, INVITE_ORGANIZATION_USER_SCHEMA, WHITELIST_SCHEMA, WITHDRAW_REQUEST_SCHEMA };
