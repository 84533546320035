import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box, Chip, CircularProgress, Modal, Skeleton, Typography } from "@mui/material";
import React, { MouseEvent } from "react";
import {
  useGetMiningProxyGroupHashrate,
  useGetMiningProxyGroupStatus,
  useGetMiningProxyGroupWorkers,
} from "../../../../app/query/useQueryGetAdmin";

export const GroupHashrate: React.FC<{ uuid: string }> = ({ uuid }) => {
  const { data: hashrateData, error, isLoading } = useGetMiningProxyGroupHashrate(uuid);

  if (isLoading) {
    return <Skeleton width={150} />;
  }

  if (error) {
    return (
      <Typography variant="body2" color="error">
        Error loading hashrate
      </Typography>
    );
  }

  return (
    <Typography variant="body2" color="textSecondary">
      {hashrateData?.hashrate || 0} H/s
    </Typography>
  );
};

export const GroupStatus: React.FC<{ uuid: string }> = ({ uuid }) => {
  const { data: statusData, error, isLoading } = useGetMiningProxyGroupStatus(uuid);

  if (isLoading) {
    return <Chip size="small" icon={<CircularProgress size={16} />} sx={{ width: "100%" }} />;
  }

  if (error) {
    return <Chip size="small" label="Error" color="error" sx={{ width: "100%" }} />;
  }

  const failedNoHashrate = statusData?.status === "failed" && statusData?.totalHashrate === 0;

  const statusName = statusData?.status
    ? statusData.status.charAt(0).toUpperCase() + statusData.status.slice(1)
    : "Unknown";

  const label = failedNoHashrate ? "No hashrate" : statusName;
  const chipColor = failedNoHashrate ? "warning" : statusData?.status === "online" ? "success" : "error";

  return <Chip size="small" label={label} color={chipColor} sx={{ width: "100%" }} />;
};

export const GroupWorkers: React.FC<{ uuid: string }> = ({ uuid }) => {
  const { data: workersData, error, isLoading } = useGetMiningProxyGroupWorkers(uuid);
  const [open, setOpen] = React.useState(false);

  const handleOpen = (e: MouseEvent) => {
    e.stopPropagation();
    setOpen(true);
  };
  const handleClose = (e: MouseEvent) => {
    e.stopPropagation();
    setOpen(false);
  };

  if (isLoading) {
    return <Skeleton width="100%" />;
  }

  if (error) {
    return (
      <Typography variant="body2" color="error">
        Error loading workers
      </Typography>
    );
  }

  const workerCount = workersData?.workers?.length || 0;

  return (
    <>
      <Chip
        size="small"
        label={`${workerCount} workers`}
        icon={<OpenInNewIcon />}
        onClick={handleOpen}
        sx={{ width: "100%", cursor: "pointer" }}
      />
      <Modal open={open} onClose={handleClose} aria-labelledby="workers-modal-title">
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            maxWidth: 800,
            maxHeight: "80vh",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            overflow: "auto",
            borderRadius: 1,
          }}
        >
          <Typography id="workers-modal-title" variant="h6" component="h2" gutterBottom>
            Workers Data
          </Typography>
          <pre style={{ margin: 0, whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
            {JSON.stringify(workersData, null, 2)}
          </pre>
        </Box>
      </Modal>
    </>
  );
};
