import { yupResolver } from "@hookform/resolvers/yup";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Skeleton,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useFieldArray, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import * as yup from "yup";
import { ReqUpdateKymCapacityAndSitesDto, ResKymCapacityAndSitesDto } from "../../app/model/api";
import { useMutationKymCapacityAndSites } from "../../app/query/useKymMutations";
import { useQueryGetKym2CapacityAndSites } from "../../app/query/useKymQueries";
import Form from "../../components/organisms/Form";
import { YUP_NOT_REQUIRED_POSITIVE_INTEGER, YUP_REQUIRED_POSITIVE_INTEGER } from "../onboarding/common.schema";

const YupFormSchema = yup
  .object({
    currentInstalledHashrate: YUP_REQUIRED_POSITIVE_INTEGER,
    estimatedHashrateIn12Months: YUP_NOT_REQUIRED_POSITIVE_INTEGER,
    totalMiningSites: YUP_REQUIRED_POSITIVE_INTEGER,
    miningSites: yup.array().of(
      yup.object({
        id: yup.string().optional(),
        location: yup.string().required("Location is required"),
        hashrate: YUP_REQUIRED_POSITIVE_INTEGER,
      })
    ),
    hasThirdPartyProviderHostedMachines: yup.boolean().required(),
    thirdPartyInstalledHashratePhS: yup.number().when("hasThirdPartyProviderHostedMachines", {
      is: true,
      then: (schema) => schema.required("This field is required when using third party hosting").positive(),
      otherwise: (schema) => schema.optional().nullable(),
    }),
    averageHostingPriceUsdPerMwh: yup.number().when("hasThirdPartyProviderHostedMachines", {
      is: true,
      then: (schema) => schema.required("This field is required when using third party hosting").positive(),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  })
  .required();

export type CapacityAndSitesFormData = yup.InferType<typeof YupFormSchema>;

export const KymCapacityAndSitesContainer = () => {
  const {
    refetch: reloadEnergyProfile,
    data: capacityAndSites,
    isFetching: isLoading,
    error,
  } = useQueryGetKym2CapacityAndSites();

  const [destroyKey, setDestroyKey] = React.useState<boolean>(false);

  const onRefetch = () => {
    setDestroyKey(!destroyKey); // force the component to be rebuilt
    reloadEnergyProfile();
  };

  return isLoading ? (
    <Stack>
      <Skeleton width={"100%"} height={300} />
    </Stack>
  ) : error ? (
    <Stack>
      <Alert severity="warning">There has been an issue loading your data. Please refresh the page to try again.</Alert>
    </Stack>
  ) : (
    <KymCapacityAndSites input={capacityAndSites?.data} key={destroyKey.toString()} triggerRefetch={onRefetch} />
  );
};

const KymCapacityAndSites = ({
  input,
  triggerRefetch,
}: {
  input: ResKymCapacityAndSitesDto | undefined;
  triggerRefetch: () => void;
}) => {
  const { mutateAsync: saveCapacityAndSites, isLoading: isSavingCapacityAndSites } = useMutationKymCapacityAndSites();

  const {
    register,
    unregister,
    control,
    watch,
    formState: { isDirty, errors },
    handleSubmit,
  } = useForm<CapacityAndSitesFormData>({
    resolver: yupResolver(YupFormSchema),
    defaultValues: {
      currentInstalledHashrate: input?.currentInstalledHashrate,
      estimatedHashrateIn12Months: input?.estimatedHashrateIn12Months,
      totalMiningSites: input?.totalMiningSites,
      miningSites: input?.miningSites ?? [],
      hasThirdPartyProviderHostedMachines: input?.hasThirdPartyProviderHostedMachines,
      thirdPartyInstalledHashratePhS: input?.thirdPartyInstalledHashratePhS,
      averageHostingPriceUsdPerMwh: input?.averageHostingPriceUsdPerMwh,
    },
  });

  const {
    fields: miningSites,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "miningSites",
  });
  const hasThirdPartyProviderHostedMachines: boolean = watch("hasThirdPartyProviderHostedMachines");

  React.useEffect(() => {
    // we need to manually register/unregister these for validation
    if (hasThirdPartyProviderHostedMachines) {
      register("thirdPartyInstalledHashratePhS");
      register("averageHostingPriceUsdPerMwh");
    } else {
      unregister("thirdPartyInstalledHashratePhS");
      unregister("averageHostingPriceUsdPerMwh");
    }
  }, [hasThirdPartyProviderHostedMachines, register, unregister]);

  const onSubmit = async (data: CapacityAndSitesFormData) => {
    const request: ReqUpdateKymCapacityAndSitesDto = {
      currentInstalledHashrate: data?.currentInstalledHashrate ?? 0,
      estimatedHashrateIn12Months: data?.estimatedHashrateIn12Months ?? undefined,
      totalMiningSites: data?.totalMiningSites ?? 0,
      miningSites:
        data?.miningSites?.map((x) => ({ id: x.id, location: x.location ?? "", hashrate: x.hashrate ?? 0 })) ?? [],
      hasThirdPartyProviderHostedMachines: data?.hasThirdPartyProviderHostedMachines,
      thirdPartyInstalledHashratePhS: data?.thirdPartyInstalledHashratePhS,
      averageHostingPriceUsdPerMwh: data?.averageHostingPriceUsdPerMwh,
    };

    try {
      const result = await saveCapacityAndSites(request);
      if (result.data.kind === false) {
        toast.error("There has been an issue while trying to save your changes. Please try again.");
      } else {
        toast.success("Your updates have been saved successfully.");
        triggerRefetch();
      }
    } catch (err) {
      toast.error("There has been an issue while trying to save your changes. Please try again.");
    }
  };

  const canAddMoreSites = miningSites.length < 5;

  return (
    <Box width={"100%"} pt={2}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Box>
            <Grid direction="row" container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  id="currentInstalledHashrate"
                  label={"Installed hashrate today"}
                  placeholder="5"
                  {...register("currentInstalledHashrate")}
                  fullWidth
                  type="number"
                  variant="outlined"
                  error={!!errors.currentInstalledHashrate}
                  helperText={
                    <>
                      {errors.currentInstalledHashrate?.message && <Box>{errors.currentInstalledHashrate.message}</Box>}
                    </>
                  }
                  onWheel={(evt) => {
                    (evt.target as HTMLElement).blur(); // disable edit by scroll
                  }}
                  InputProps={{
                    inputProps: { min: 0 },
                    endAdornment: <InputAdornment position="end">PH/s</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} alignItems={"center"}>
                <TextField
                  id="estimatedHashrateIn12Months"
                  label={"[Optional] Estimated installed hashrate in 12 months"}
                  {...register("estimatedHashrateIn12Months")}
                  placeholder="10"
                  fullWidth
                  variant="outlined"
                  type="number"
                  onWheel={(evt) => {
                    (evt.target as HTMLElement).blur();
                  }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">PH/s</InputAdornment>,
                  }}
                  error={!!errors.estimatedHashrateIn12Months}
                  helperText={
                    <>{errors.estimatedHashrateIn12Months && <Box>{errors.estimatedHashrateIn12Months.message}</Box>}</>
                  }
                />
              </Grid>
            </Grid>
          </Box>

          <Grid
            mt={2}
            container
            padding={2}
            pb={4}
            boxShadow={2}
            border={1}
            borderRadius={1}
            borderColor={"ThreeDFace"}
          >
            <Grid container item xs={12} md={6} mt={2}>
              <TextField
                id="totalMiningSites"
                label={"How many mining sites are you operating?"}
                {...register("totalMiningSites")}
                placeholder="10"
                fullWidth
                variant="outlined"
                type="number"
                onWheel={(evt) => {
                  (evt.target as HTMLElement).blur();
                }}
                InputProps={{
                  inputProps: { min: 1 },
                }}
                error={!!errors.totalMiningSites}
                helperText={<>{errors.totalMiningSites?.message && <Box>{errors.totalMiningSites.message}</Box>}</>}
              />
            </Grid>
            <Grid container item alignItems={"center"} mt={1}>
              <Typography>You can record at most 5 mining sites</Typography>
              <span>
                <IconButton
                  color="primary"
                  onClick={() => append({ id: "", location: "", hashrate: 0 })}
                  disabled={!canAddMoreSites}
                >
                  <AddCircleOutlineIcon />
                </IconButton>
              </span>
            </Grid>

            {miningSites.map((item, index) => {
              return (
                <Grid container item key={`grid.miningSites.${index}.name`} spacing={2} mt={1} alignContent={"center"}>
                  <Grid item xs={12} md={6} alignContent={"center"}>
                    <Box display={"flex"} alignContent={"center"}>
                      <IconButton onClick={() => remove(index)} color="primary">
                        <DeleteIcon />
                      </IconButton>
                      <TextField
                        id={`location-${index}`}
                        label={"Geographic location (city, country)"}
                        {...register(`miningSites.${index}.location`)}
                        placeholder="Dublin, Ireland"
                        fullWidth
                        variant="outlined"
                        error={!!errors.miningSites?.[index]?.location}
                        helperText={
                          errors.miningSites?.[index]?.location?.message && (
                            <Box>{errors.miningSites?.[index]?.location?.message}</Box>
                          )
                        }
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} alignItems={"center"}>
                    <TextField
                      id={`miningSites.${index}.hashrate`}
                      label={"Hashrate"}
                      {...register(`miningSites.${index}.hashrate`)}
                      placeholder="10"
                      fullWidth
                      variant="outlined"
                      type="number"
                      onWheel={(evt) => {
                        (evt.target as HTMLElement).blur();
                      }}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">PH/s</InputAdornment>,
                      }}
                      error={!!errors.miningSites?.[index]?.hashrate}
                      helperText={
                        errors.miningSites?.[index]?.hashrate?.message && (
                          <Box>{errors.miningSites?.[index]?.hashrate?.message}</Box>
                        )
                      }
                    />
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
          <Box mt={4}>
            <Typography variant="subtitle1">Hosted mining sites</Typography>
            <Grid container item alignItems={"center"} mt={2}>
              <FormControl component="fieldset">
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      {...register("hasThirdPartyProviderHostedMachines")}
                      checked={hasThirdPartyProviderHostedMachines}
                    />
                  }
                  label="Are you hosting machines at a third-party provider?"
                  labelPlacement="end"
                />
              </FormControl>
            </Grid>
            {hasThirdPartyProviderHostedMachines && (
              <Grid direction="row" container spacing={2} mt={1}>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="thirdOverall"
                    label={"Overall installed hashrate at third-party hosts"}
                    placeholder="5"
                    {...register("thirdPartyInstalledHashratePhS")}
                    fullWidth
                    type="number"
                    variant="outlined"
                    error={!!errors.thirdPartyInstalledHashratePhS}
                    helperText={
                      errors.thirdPartyInstalledHashratePhS?.message && (
                        <Box>{errors.thirdPartyInstalledHashratePhS.message}</Box>
                      )
                    }
                    onWheel={(evt) => {
                      (evt.target as HTMLElement).blur(); // disable edit by scroll
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">PH/s</InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="averageHostingPriceUsdPerMwh"
                    label={"Average hosting price paid"}
                    placeholder="5"
                    {...register("averageHostingPriceUsdPerMwh")}
                    fullWidth
                    type="number"
                    variant="outlined"
                    error={!!errors.averageHostingPriceUsdPerMwh}
                    helperText={
                      errors.averageHostingPriceUsdPerMwh?.message && (
                        <Box>{errors.averageHostingPriceUsdPerMwh.message}</Box>
                      )
                    }
                    onWheel={(evt) => {
                      (evt.target as HTMLElement).blur(); // disable edit by scroll
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">USD/MWh</InputAdornment>,
                    }}
                  />
                </Grid>
              </Grid>
            )}
          </Box>
          <Box mt={2} gap={2} display="flex" alignItems={"center"} justifyContent={"flex-end"}>
            <LoadingButton
              type="submit"
              loading={isSavingCapacityAndSites}
              disabled={!isDirty}
              variant="contained"
              sx={{ height: 40 }}
            >
              Save
            </LoadingButton>
          </Box>
        </Box>
      </Form>
    </Box>
  );
};
