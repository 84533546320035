import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { useState } from "react";
import { GroupDto } from "../../../../app/model/api";
import { useGetMiningProxyGroupHashrate } from "../../../../app/query/useQueryGetAdmin";
import { LabelValue } from "./LabelValue";

interface EditPoolModalProps {
  open: boolean;
  onClose: () => void;
  group: GroupDto;
  onSave: (defaultPool: string) => void;
}

export const EditPoolModal = ({ open, onClose, group, onSave }: EditPoolModalProps) => {
  const [defaultPool, setDefaultPool] = useState(group.defaultPool);
  const { data: hashrateData } = useGetMiningProxyGroupHashrate(group.uuid);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Edit Default Pool</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={1} mt={1}>
          <Alert severity="error" sx={{ mb: 2 }}>
            The proxy API endpoint is currently experiencing issues. Changes here will have no effect. The proxy
            developers are working on a fix.
          </Alert>
          <LabelValue label="Group" value={group.name} />
          <LabelValue label="Hashrate" value={`${hashrateData?.hashrate || 0} H/s`} />
          <LabelValue label="Proxy Address" value={`${group.endpoint}:${group.port}`} />
          <Box mt={2}>
            <TextField
              label="New Default Pool"
              value={defaultPool}
              onChange={(e) => setDefaultPool(e.target.value)}
              fullWidth
              autoFocus
              required
              helperText="Include username and password in URL if required (e.g. stratum+tcp://user:pass@pool.example.com:3333)"
              placeholder="stratum+tcp://user:pass@pool.example.com:3333"
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={() => onSave(defaultPool)} variant="contained" color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
