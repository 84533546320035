import * as yup from "yup";
import { LP_GRANULAR_TIME_HORIZON_OF_LIQUIDITY, SELECT_COUNTRY } from "../../app/constants";
import { PrimaryContactDtoHasPowerOfAttorneyEnum, PrimaryContactDtoPrimaryContactKindEnum } from "../../app/model/api";
const ERR_STANDARD_REQUIRED = "This field is required.";

export const PRIMARY_CONTACT_VALUES = ["signup-user", "notsure", "another-person"] as const;
export type PrimaryContactType = (typeof PRIMARY_CONTACT_VALUES)[number];

export const POWER_OF_ATTORNEY_VALUES = ["yes", "no", "notsure"] as const;
export type PowerOfAttorneyType = (typeof POWER_OF_ATTORNEY_VALUES)[number];

const YUP_REQUIRED_EMAIL = yup.string().email("Provided email is not valid.").required(ERR_STANDARD_REQUIRED);
const YUP_REQUIRED_STRING = yup.string().required(ERR_STANDARD_REQUIRED);

const YUP_BASE_POSITIVE_INTEGER = yup
  .number()
  .nullable()
  .transform((value) => (Number.isNaN(value) ? null : value))
  .integer("This value has to be a positive integer!")
  .positive("This value has to be a positive integer!");

export const YUP_REQUIRED_POSITIVE_INTEGER = YUP_BASE_POSITIVE_INTEGER.required(
  "This value has to be a positive integer!"
);

export const YUP_NOT_REQUIRED_POSITIVE_INTEGER = YUP_BASE_POSITIVE_INTEGER.nullable().notRequired();

export const YUP_ANOTHER_USER = yup.string().when("primaryContact", {
  is: "another-person",
  then: () => yup.string().required("This field is required"),
  otherwise: () => yup.string().optional(),
});

const YUP_OPTIONAL_URL = yup
  .string()
  .nullable()
  .matches(
    /^(((https?):\/\/)?(www\.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?)?$/,
    "Please provide a valid url!"
  )
  .optional();

export const YUP_REQUIRED_URL = yup
  .string()
  .required("Please provide a valid url!")
  .matches(
    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    "Please provide a valid url!"
  );

export const PUBLICLY_LISTED_INFO =
  "Due to our regulatory status as a financial intermediary, we perform a standard KYC/AML check on all client relationships." +
  " Depending on whether your company is publicly listed or privately held, we require a different set of information for KYC purposes.";

export interface CommonOnboardingForm {
  readonly captcha?: "valid";

  readonly currentUserfirstname?: string;
  readonly currentUserlastname?: string;
  readonly currentUserphone?: string;
  readonly currentUseremail?: string;

  readonly companyLegalName?: string;
  readonly roleAtCompany?: string;
  readonly countryOfIncorporation?: string;
  readonly homepageUrl?: string;
  readonly isPubliclyListed: boolean;

  readonly primaryContact: PrimaryContactType;

  readonly anotherUserfirstname?: string;
  readonly anotherUserlastname?: string;
  readonly anotherUseremail?: string;
  readonly anotherUserphone?: string;

  readonly powerOfAttorney: PowerOfAttorneyType;

  readonly timeHorizon: number;
}

export const YUP_COMMON = {
  currentUserfirstname: YUP_REQUIRED_STRING,
  currentUserlastname: YUP_REQUIRED_STRING,
  currentUserphone: YUP_REQUIRED_STRING,
  currentUseremail: YUP_REQUIRED_EMAIL,

  companyLegalName: YUP_REQUIRED_STRING,
  roleAtCompany: YUP_REQUIRED_STRING,
  homepageUrl: YUP_OPTIONAL_URL,
  countryOfIncorporation: YUP_REQUIRED_STRING.oneOf(SELECT_COUNTRY.map((x) => x.label)),
  isPubliclyListed: yup.boolean().required(),

  primaryContact: yup.mixed<PrimaryContactType>().required().oneOf(PRIMARY_CONTACT_VALUES).defined(),
  powerOfAttorney: yup.mixed<PowerOfAttorneyType>().required().oneOf(POWER_OF_ATTORNEY_VALUES).defined(),

  anotherUserfirstname: YUP_ANOTHER_USER,
  anotherUserlastname: YUP_ANOTHER_USER,
  anotherUseremail: YUP_ANOTHER_USER.email("Provided email is not valid."),
  anotherUserphone: YUP_ANOTHER_USER,

  timeHorizon: yup
    .number()
    .required()
    .oneOf(LP_GRANULAR_TIME_HORIZON_OF_LIQUIDITY.map((x) => x.value)),
  // Leave type as string but only allow "valid", so validation fails in other cases
  captcha: yup.mixed<string>().required("Please complete the challenge to continue.").oneOf(["valid"]),
};

export const primaryContactStringToEnum = (input: PrimaryContactType): PrimaryContactDtoPrimaryContactKindEnum => {
  switch (input) {
    case "signup-user":
      return PrimaryContactDtoPrimaryContactKindEnum.SignupUser;
    case "notsure":
      return PrimaryContactDtoPrimaryContactKindEnum.Notsure;
    case "another-person":
      return PrimaryContactDtoPrimaryContactKindEnum.AnotherPerson;
  }
};
export const powerOfAttorneyStringToEnum = (input: PowerOfAttorneyType): PrimaryContactDtoHasPowerOfAttorneyEnum => {
  switch (input) {
    case "yes":
      return PrimaryContactDtoHasPowerOfAttorneyEnum.Yes;
    case "no":
      return PrimaryContactDtoHasPowerOfAttorneyEnum.No;
    case "notsure":
      return PrimaryContactDtoHasPowerOfAttorneyEnum.Notsure;
  }
};
