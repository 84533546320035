import { Box, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { CustomStepConnector, CustomStepIcon } from "./StageContent";

interface ContinuousActionProps {
  completed: boolean;
  current: boolean;
  collateralStatus: "Sufficient" | "Under sufficient";
  lastRewardAt: Date | null;
}

interface Instruction {
  text: string;
  color: "error.main" | "warning.main";
}

export const ContinuousAction = ({ current, completed, collateralStatus, lastRewardAt }: ContinuousActionProps) => {
  const isUnderCollateralized = collateralStatus === "Under sufficient";
  const lastRewardDate = lastRewardAt ? new Date(lastRewardAt) : null;
  const hoursSinceLastReward = lastRewardDate
    ? Math.floor((Date.now() - lastRewardDate.getTime()) / (1000 * 60 * 60))
    : 0;
  const noRewardsFor30h = hoursSinceLastReward > 30;

  // Determine step state based on status
  const getStepState = () => {
    if (completed) return "completed";
    if (!current) return "future";
    if (noRewardsFor30h) return "error";
    if (isUnderCollateralized) return "warning";
    return "current";
  };

  const getInstructions = (): Instruction[] => {
    if (!current) return [];

    const instructions: Instruction[] = [];

    if (noRewardsFor30h) {
      const daysSinceLastReward = Math.floor(hoursSinceLastReward / 24);
      instructions.push({
        text: `No rewards received for ${daysSinceLastReward} day${daysSinceLastReward !== 1 ? "s" : ""}`,
        color: "error.main",
      });
    }

    if (isUnderCollateralized) {
      instructions.push({
        text: "Collateral balance is too low",
        color: "warning.main",
      });
    }

    return instructions;
  };

  const instructions = getInstructions();

  return (
    <Box>
      <Stepper
        activeStep={current ? 0 : -1}
        orientation="vertical"
        connector={<CustomStepConnector isCurrentStage={current} />}
        sx={{
          mt: 1,
          mb: 1,
          "& .MuiStepLabel-root": {
            p: 0,
            ml: -0.5,
          },
          "& .MuiStepLabel-labelContainer": {
            ml: 1,
          },
          "& .MuiStep-root": {
            mb: -1,
            mt: -1,
          },
        }}
      >
        <Step>
          <StepLabel
            StepIconComponent={() => (
              <CustomStepIcon
                ownerState={{
                  isCurrentStage: current,
                  isContinuousAction: true,
                  stepState: getStepState(),
                }}
              />
            )}
          >
            <Typography variant="body2" color={current ? "text.primary" : "text.secondary"}>
              Deliver sufficient rewards
            </Typography>
            <Typography variant="caption" color="text.secondary">
              Miner
            </Typography>
          </StepLabel>
        </Step>
      </Stepper>
      {instructions.length > 0 && (
        <Box mt={1} ml={3} display="flex" flexDirection="column" gap={0.5}>
          {instructions.map((instruction, index) => (
            <Typography key={index} variant="caption" color={instruction.color} sx={{ display: "block" }}>
              {instruction.text}
            </Typography>
          ))}
        </Box>
      )}
    </Box>
  );
};
