import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { ContractDto, GroupDto } from "../../../../app/model/api";
import { useForm, Controller } from "react-hook-form";

export interface ContractFormData {
  miningPoolURL: string;
  miningPoolUser: string;
  miningPoolPass: string;
  assignedHashrate: number;
  workernameOption: string;
}

type ContractModalProps = {
  open: boolean;
  onClose: () => void;
  onSave: (data: ContractFormData) => void;
} & ({ group: GroupDto; contract?: undefined } | { contract: ContractDto; group?: undefined });

export const ContractModal = ({ open, onClose, onSave, group, contract }: ContractModalProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ContractFormData>({
    defaultValues: {
      miningPoolURL: contract?.miningPoolURL ?? "",
      miningPoolUser: contract?.miningPoolUser ?? "",
      miningPoolPass: contract?.miningPoolPass ?? "",
      assignedHashrate: contract?.assignedHashrate,
      workernameOption: contract?.workernameOption ?? "none",
    },
  });

  const onSubmit = handleSubmit((data) => {
    onSave(data);
  });

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{group ? `Add Contract to ${group.name}` : "Edit Contract"}</DialogTitle>
      <DialogContent>
        <Box component="form" display="flex" flexDirection="column" gap={2} mt={1}>
          <Controller
            name="miningPoolURL"
            control={control}
            rules={{ required: "Mining Pool URL is required" }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Mining Pool URL"
                fullWidth
                required
                error={!!errors.miningPoolURL}
                helperText={
                  errors.miningPoolURL?.message ||
                  "Pool URL without credentials (e.g. stratum+tcp://pool.example.com:3333)"
                }
                placeholder="stratum+tcp://pool.example.com:3333"
              />
            )}
          />
          <Controller
            name="miningPoolUser"
            control={control}
            render={({ field }) => <TextField {...field} label="Username" fullWidth />}
          />
          <Controller
            name="miningPoolPass"
            control={control}
            render={({ field }) => <TextField {...field} label="Password" fullWidth />}
          />
          <Controller
            name="assignedHashrate"
            control={control}
            rules={{
              required: "Assigned hashrate is required",
              min: { value: 1, message: "Assigned hashrate must be greater than 0" },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Assigned Hashrate (H/s)"
                type="number"
                fullWidth
                required
                error={!!errors.assignedHashrate}
                helperText={errors.assignedHashrate?.message}
              />
            )}
          />
          <Controller
            name="workernameOption"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <InputLabel>Worker ID Mode</InputLabel>
                <Select {...field} label="Worker ID Mode">
                  <MenuItem value="passthrough">Passthrough</MenuItem>
                  <MenuItem value="username">Username</MenuItem>
                  <MenuItem value="none">None</MenuItem>
                </Select>
                <FormHelperText>
                  Passthrough: miner's worker ID is used as the worker ID to the pool
                  <br />
                  Username: miner's username is used as the worker ID to the pool
                  <br />
                  None: No worker ID used
                </FormHelperText>
              </FormControl>
            )}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onSubmit} variant="contained" color="primary">
          {contract ? "Save Changes" : "Add Contract"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
