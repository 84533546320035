import { Grid, Paper, Table, TableBody, TableCell, TableContainer, Typography } from "@mui/material";
import { format } from "date-fns";
import { ReqUpdateOpportunityV2Dto } from "../../../../app/model/api";
import { StyledTableRow } from "../../commons";
export const MinerOpportunityFormReadonly = ({ opportunity }: { opportunity: ReqUpdateOpportunityV2Dto }) => {
  const opportunityRows1: { title: string; value: JSX.Element | null }[] = [
    {
      title: "Required hashrate for 100% of opportunity",
      value: (
        <Typography gutterBottom variant="body2" noWrap>
          {`${opportunity.requiredHashPowerForCompletionPhPerS} PH/s`}
        </Typography>
      ),
    },
    {
      title: "Max amount of liquidity",
      value: (
        <Typography gutterBottom variant="body2" noWrap>
          {`${opportunity.maximumAmountOfLiquidityBtc} BTC`}
        </Typography>
      ),
    },
    {
      title: "Duration of agreement",
      value: (
        <Typography gutterBottom variant="body2" noWrap>
          {`${opportunity.durationOfAgreementDays} days`}
        </Typography>
      ),
    },
    {
      title: "Structuring fee percentage",
      value: (
        <Typography gutterBottom variant="body2" noWrap>
          {`${opportunity.structuringFeePercentage}%`}
        </Typography>
      ),
    },
    {
      title: "Minimum reward delivery",
      value: (
        <Typography gutterBottom variant="body2" noWrap>
          {opportunity.minRewardDeliverySatoshis ? `${opportunity.minRewardDeliverySatoshis} satoshis` : "-"}
        </Typography>
      ),
    },
  ];
  const opportunityRows2: { title: string; value: JSX.Element | null }[] = [
    {
      title: "Minimum collateral as % of initial liquidity",
      value: (
        <Typography gutterBottom variant="body2" noWrap>
          {`${opportunity.initialCollateralPercentage}%`}
        </Typography>
      ),
    },
    {
      title: "Expected annualized rate of return",
      value: (
        <Typography gutterBottom variant="body2" noWrap>
          {`${opportunity.expectedReturnPercentage}%`}
        </Typography>
      ),
    },
    {
      title: "Management fee percentage",
      value: (
        <Typography gutterBottom variant="body2" noWrap>
          {`${opportunity.managementFeePercentage}%`}
        </Typography>
      ),
    },
    {
      title: "Funding deadline",
      value: (
        <Typography gutterBottom variant="body2" noWrap>
          {format(new Date(opportunity.fundingDeadline), "dd MMM yyyy")}
        </Typography>
      ),
    },
    {
      title: "Maximum reward delivery",
      value: (
        <Typography gutterBottom variant="body2" noWrap>
          {opportunity.maxRewardDeliverySatoshis ? `${opportunity.maxRewardDeliverySatoshis} satoshis` : "-"}
        </Typography>
      ),
    },
  ];

  return (
    <Grid container item xs={12}>
      <Grid container item xs={12} md={6}>
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table>
            <TableBody>
              {opportunityRows1.map((row, index) => (
                <StyledTableRow key={"row-op-info-" + index}>
                  <TableCell size="small" align="left" width={400}>
                    <Typography gutterBottom variant="subtitle2" fontWeight={"fontWeightBold"}>
                      {row.title}
                    </Typography>
                  </TableCell>
                  <TableCell size="small" align="left">
                    {row.value}
                  </TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid container item xs={12} md={6}>
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table>
            <TableBody>
              {opportunityRows2.map((row, index) => (
                <StyledTableRow key={"row-op-info-" + index}>
                  <TableCell size="small" align="left" width={400}>
                    <Typography gutterBottom variant="subtitle2" fontWeight={"fontWeightBold"}>
                      {row.title}
                    </Typography>
                  </TableCell>
                  <TableCell size="small" align="left">
                    {row.value}
                  </TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};
