import { Box, Typography } from "@mui/material";

interface LabelValueProps {
  label: string;
  value: React.ReactNode;
}

export const LabelValue = ({ label, value }: LabelValueProps) => (
  <Box display="flex" alignItems="center" gap={1}>
    <Typography variant="body2" color="textSecondary" sx={{ minWidth: 200 }}>
      {label}:
    </Typography>
    <Typography variant="body2" color="textSecondary" sx={{ flex: 1 }}>
      {value}
    </Typography>
  </Box>
);
