import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  Skeleton,
  TableSortLabel,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { format } from "date-fns";
import React from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import * as yup from "yup";
import { isApiError } from "../../../app/constants";
import { GetUsersParamsOrderByEnum, GetUsersParamsOrderDirectionEnum } from "../../../app/model/api";
import { useMutationAddAdminUser } from "../../../app/query/useMutationAdmin";
import { useGetAdminUsers } from "../../../app/query/useQueryGetAdmin";
import { MuiFullscreenLoadingBackdrop } from "../../../components/atoms/MuiCircularPercentage/MuiLoadingBackdrop";

interface Column {
  id: string;
  label: string;
  orderField?: GetUsersParamsOrderByEnum | undefined;
  minWidth?: number;
  width?: number;
  align?: "right";
}

const columns: readonly Column[] = [
  { id: "createdAt", label: "Created at", minWidth: 170, orderField: GetUsersParamsOrderByEnum.CreatedAt },
  { id: "email", label: "Email", orderField: GetUsersParamsOrderByEnum.Email },
  { id: "firstName", label: "First name", orderField: GetUsersParamsOrderByEnum.FirstName },
  { id: "lastName", label: "Last name", orderField: GetUsersParamsOrderByEnum.LastName },
];

export type FormData = yup.InferType<typeof YupFormSchema>;

const YupFormSchema = yup
  .object({})
  .shape({
    email: yup
      .string()
      .email("This needs to be a valid email.")
      .required("Please provide a block.green email.")
      .matches(/@block.green$/, "Only block.green emails can be assigned as admins."),
    firstName: yup.string().required("Please provide a first name."),
    lastName: yup.string().required("Please provide a last name."),
  })
  .required();

export const AdminUserManagement = () => {
  const [orderBy, setOrderBy] = React.useState<GetUsersParamsOrderByEnum>(GetUsersParamsOrderByEnum.CreatedAt);
  const [orderDirection, setOrderDirection] = React.useState<GetUsersParamsOrderDirectionEnum>(
    GetUsersParamsOrderDirectionEnum.Desc
  );
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const { data, isFetching, refetch } = useGetAdminUsers({
    limit: rowsPerPage,
    offset: page * rowsPerPage,
    orderBy: orderBy === "createdAt" ? GetUsersParamsOrderByEnum.CreatedAt : GetUsersParamsOrderByEnum.Email,
    orderDirection:
      orderDirection === "asc" ? GetUsersParamsOrderDirectionEnum.Asc : GetUsersParamsOrderDirectionEnum.Desc,
  });
  const totalCount = data?.data.count;
  const rows = data?.data.rows;

  const {
    register,
    formState: { isDirty, errors },
    handleSubmit,
    reset,
  } = useForm<FormData>({
    resolver: yupResolver(YupFormSchema),
    defaultValues: {},
  });
  const [openConfirmation, setOpenConfirmation] = React.useState<undefined | FormData>();
  const { mutateAsync: saveNewUser, isLoading: isSaving } = useMutationAddAdminUser();
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const formatDate = (input: string | Date | number) => {
    const date = new Date(input);
    return format(date, "dd/MM/yy HH:mm");
  };

  const onTrySubmit = async (data: FormData) => {
    setOpenConfirmation(data);
  };

  const handleAddAdmin = async (data: FormData) => {
    setOpenConfirmation(undefined);
    try {
      await saveNewUser(data);
      refetch();
      reset();
      toast.success("User has been created successfully.");
    } catch (error: unknown) {
      if (isApiError(error)) toast.error("There has been an issue with the operation: " + error.error.message);
      else toast.error("There has been an unknown issue with the operation.");
    }
  };

  return (
    <Box width={"100%"}>
      <Container sx={{ background: "white", maxWidth: 1200, pt: 3, pb: 3 }}>
        <MuiFullscreenLoadingBackdrop isOpen={isSaving} />
        <Dialog open={!!openConfirmation} onClose={() => setOpenConfirmation(undefined)}>
          <DialogTitle id="alert-dialog-title">{"Confirm action"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to assign {openConfirmation?.email} as an admin?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                if (openConfirmation) handleAddAdmin(openConfirmation);
              }}
            >
              Yes
            </Button>
            <Button onClick={() => setOpenConfirmation(undefined)} autoFocus>
              No
            </Button>
          </DialogActions>
        </Dialog>
        <form onSubmit={handleSubmit(onTrySubmit)} encType="multipart/form-data">
          <Grid container item spacing={1}>
            <Grid container item xs={12} md={4}>
              <TextField
                id="email"
                label={"Email"}
                placeholder="example@block.green"
                disabled={isFetching || isSaving}
                {...register("email")}
                fullWidth
                variant="outlined"
                error={!!errors.email}
                helperText={<>{errors.email?.message && <Box>{errors.email.message}</Box>}</>}
              />
            </Grid>
            <Grid container item xs={12} md={4}>
              <TextField
                id="firstname"
                label={"First name"}
                disabled={isFetching || isSaving}
                placeholder="Jessica"
                {...register("firstName")}
                fullWidth
                variant="outlined"
                error={!!errors.firstName}
                helperText={<>{errors.firstName?.message && <Box>{errors.firstName.message}</Box>}</>}
              />
            </Grid>
            <Grid container item xs={12} md={4}>
              <TextField
                id="lastname"
                label={"Last name"}
                disabled={isFetching || isSaving}
                placeholder="Atreides"
                {...register("lastName")}
                fullWidth
                variant="outlined"
                error={!!errors.lastName}
                helperText={<>{errors.lastName?.message && <Box>{errors.lastName.message}</Box>}</>}
              />
            </Grid>
            <Grid container item xs={12} justifyContent={"flex-end"}>
              <LoadingButton
                type="submit"
                disabled={!isDirty || isFetching}
                loading={isSaving}
                variant="contained"
                sx={{ height: 40 }}
              >
                Save
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
        <Paper sx={{ boxShadow: 5, pt: 1, mt: 2 }}>
          <TableContainer sx={{ maxHeight: "60vh" }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow sx={{ backgroundColor: "red" }}>
                  {columns.map((column) =>
                    column.orderField ? (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        sortDirection={orderBy === column.orderField ? orderDirection : undefined}
                      >
                        <TableSortLabel
                          active={orderBy === column.orderField}
                          direction={orderBy === column.orderField ? orderDirection : undefined}
                          onClick={() => {
                            if (column.orderField) {
                              setOrderBy(column.orderField);
                              setOrderDirection(
                                orderDirection === GetUsersParamsOrderDirectionEnum.Asc
                                  ? GetUsersParamsOrderDirectionEnum.Desc
                                  : GetUsersParamsOrderDirectionEnum.Asc
                              );
                            }
                          }}
                        >
                          {column.label}
                        </TableSortLabel>
                      </TableCell>
                    ) : (
                      <TableCell key={column.id} align={column.align}>
                        {column.label}
                      </TableCell>
                    )
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.length
                  ? rows.map((row) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                          <TableCell align={"left"} sx={{ minWidth: 175, maxWidth: 175 }}>
                            <Tooltip title={row.createdAt}>
                              <Typography noWrap>{formatDate(row.createdAt)}</Typography>
                            </Tooltip>
                          </TableCell>
                          <TableCell align={"left"} sx={{ minWidth: 250, maxWidth: 250 }}>
                            <Tooltip title={row.email}>
                              <Typography noWrap>{row.email}</Typography>
                            </Tooltip>
                          </TableCell>
                          <TableCell align={"left"} sx={{ minWidth: 175, maxWidth: 175 }}>
                            <Tooltip title={row.firstName}>
                              <Typography noWrap>{row.firstName}</Typography>
                            </Tooltip>
                          </TableCell>
                          <TableCell align={"left"} sx={{ minWidth: 175, maxWidth: 175 }}>
                            <Tooltip title={row.lastName}>
                              <Typography noWrap>{row.lastName}</Typography>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  : null}
              </TableBody>
            </Table>

            {!isFetching && !rows?.length && (
              <Box width="100%" padding={2} display={"flex"}>
                <Typography width={"100%"} variant="caption" color="silver" textAlign={"center"}>
                  No available data
                </Typography>
              </Box>
            )}

            {isFetching && (
              <Box width="100%" pl={2} pr={2}>
                <Skeleton width={"100%"} height={50}></Skeleton>
                <Skeleton width={"100%"} height={50}></Skeleton>
                <Skeleton width={"100%"} height={50}></Skeleton>
                <Skeleton width={"100%"} height={50}></Skeleton>
                <Skeleton width={"100%"} height={50}></Skeleton>
              </Box>
            )}
          </TableContainer>
          {rows?.length && totalCount ? (
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          ) : (
            <></>
          )}
        </Paper>
      </Container>
    </Box>
  );
};
