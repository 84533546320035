import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  Skeleton,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import React, { useReducer } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import * as yup from "yup";
import { KymAsicDto, ReqUpdateKymAsicDto } from "../../app/model/api";
import { useMutationKymAsic } from "../../app/query/useKymMutations";
import { useQueryGetKym2Asic } from "../../app/query/useKymQueries";
import { ChipFileItem, DisplayFileList } from "../../components/atoms/FileInput/ChipFile";
import { MuiFileDragAndDropUploader } from "../../components/atoms/FileInput/MuiFileDragAndDropUploader";
import { UploadGridRow, buildChipFileItem, mapFiles } from "./commons";

export type FormData = yup.InferType<typeof YupFormSchema>;
type Sections = "machine-list" | "invoices" | "uptime";

type FileAction =
  | {
      kind: "remove";
      section: Sections;
      id: string;
    }
  | { kind: "add"; section: Sections; files: File[] };

interface AsicFormFilesState {
  hasUpdates: boolean;
  files: {
    [key in Sections]: ChipFileItem[];
  };
}

const reducer = (state: AsicFormFilesState, action: FileAction): AsicFormFilesState => {
  switch (action.kind) {
    case "add": {
      const crt = state.files[action.section] ?? [];
      const newFiles = [
        ...crt.filter((x) => !action.files.some((newFile) => newFile.name === x.id)),
        ...action.files.map((x) => {
          const file: ChipFileItem = { kind: "new", id: x.name, file: x };
          return file;
        }),
      ];
      const newState = {
        hasUpdates: true,
        files: {
          ...state.files,
          [action.section]: newFiles,
        },
      };
      return newState;
    }

    case "remove": {
      const newFiles = (state.files[action.section] ?? []).filter((x) => x.id !== action.id);
      const newState = {
        hasUpdates: true,
        files: {
          ...state.files,
          [action.section]: newFiles,
        },
      };
      return newState;
    }
  }
};

const ERROR_POSITIVE_INTEGER = "This value has to be a positive integer!";

const YupPositiveNumber = yup
  .number()
  .typeError(ERROR_POSITIVE_INTEGER)
  .required(ERROR_POSITIVE_INTEGER)
  .transform((value) => (Number.isNaN(value) ? null : value))
  .positive(ERROR_POSITIVE_INTEGER)
  .integer(ERROR_POSITIVE_INTEGER);

const ERROR_PERCENTAGE = "This value has to be an integer between 0 and 100!";
const YupPercentage = yup
  .number()
  .typeError(ERROR_PERCENTAGE)
  .required(ERROR_PERCENTAGE)
  .transform((value) => (Number.isNaN(value) ? null : value))
  .min(0, ERROR_PERCENTAGE)
  .max(100, ERROR_PERCENTAGE)
  .integer(ERROR_PERCENTAGE);

const YupFormSchema = yup
  .object({
    averageFleetEfficiencyJPerTh: YupPositiveNumber,
    asicUptimePercentage: YupPercentage,
    capacityEncumbrancePercentage: YupPercentage,
    averageAsicPurchasePriceUsdPerTh: yup
      .number()
      .typeError(ERROR_POSITIVE_INTEGER)
      .positive(ERROR_POSITIVE_INTEGER)
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .optional(),
    hasInsurance: yup.boolean().required("This field is required"),
    insuranceDescription: yup.string().when("hasInsurance", {
      is: true,
      then: () => yup.string().optional(),
      otherwise: () => yup.string().optional().nullable(),
    }),
  })
  .required();

export const KymAsicContainer = () => {
  const { refetch: reloadAsicFleet, data: asicFleet, isFetching: isLoadingHosting } = useQueryGetKym2Asic();

  const [destroyKey, setDestroyKey] = React.useState<boolean>(false);

  const onRefetch = () => {
    setDestroyKey(!destroyKey); // force the component to be rebuilt
    reloadAsicFleet();
  };

  return isLoadingHosting ? (
    <Stack>
      <Skeleton width={"100%"} height={300} />
    </Stack>
  ) : (
    <KymAsic key={destroyKey.toString()} input={asicFleet?.data.payload} triggerRefetch={onRefetch}></KymAsic>
  );
};

const KymAsic = ({ input, triggerRefetch }: { input: KymAsicDto | undefined; triggerRefetch: () => void }) => {
  const { mutateAsync: saveAsic, isLoading: isSavingAsic } = useMutationKymAsic();

  const {
    register,
    unregister,
    watch,
    formState: { isDirty, errors },
    handleSubmit,
  } = useForm<FormData>({
    resolver: yupResolver(YupFormSchema),
    defaultValues: {
      averageFleetEfficiencyJPerTh: input?.averageFleetEfficiencyJPerTh ?? 0,
      averageAsicPurchasePriceUsdPerTh: input?.averageAsicPurchasePriceUsdPerTh,
      asicUptimePercentage: input?.asicUptimePercentage ?? 0,
      capacityEncumbrancePercentage: input?.capacityEncumbrancePercentage ?? 0,
      hasInsurance: input?.hasInsurance ?? false,
      insuranceDescription: input?.insuranceDescription,
    },
  });

  const [filesState, dispatch] = useReducer(reducer, {
    hasUpdates: false,
    files: {
      "machine-list": input?.machineListFiles.map(buildChipFileItem) ?? [],
      invoices: input?.machineInvoiceFiles.map(buildChipFileItem) ?? [],
      uptime: input?.minerUptimeStatsFiles.map(buildChipFileItem) ?? [],
    },
  });

  const hasInsurance = watch("hasInsurance");

  React.useEffect(() => {
    if (hasInsurance) {
      register("insuranceDescription");
    } else {
      unregister("insuranceDescription");
    }
  }, [hasInsurance, register, unregister]);

  const onSubmit = async (data: FormData) => {
    const request: ReqUpdateKymAsicDto = {
      averageFleetEfficiencyJPerTh: data.averageFleetEfficiencyJPerTh,
      machineListFiles: await mapFiles(filesState.files["machine-list"] ?? []),
      averageAsicPurchasePriceUsdPerTh: data.averageAsicPurchasePriceUsdPerTh,
      machineInvoiceFiles: await mapFiles(filesState.files["invoices"] ?? []),
      asicUptimePercentage: data.asicUptimePercentage,
      minerUptimeStatsFiles: await mapFiles(filesState.files["uptime"] ?? []),
      capacityEncumbrancePercentage: data.capacityEncumbrancePercentage,
      hasInsurance: data.hasInsurance,
      insuranceDescription: data.hasInsurance ? data.insuranceDescription : undefined,
    };

    try {
      const result = await saveAsic(request);
      if (result.data.kind === false) {
        toast.error("There has been an issue while trying to save your changes. Please try again.");
      } else {
        toast.success("Your updates have been saved successfully.");
        triggerRefetch();
      }
    } catch (err) {
      toast.error("There has been an issue while trying to save your changes. Please try again.");
    }
  };

  return (
    <Box width={"100%"}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <UploadGridRow>
            <Grid item xs={12} md={6}>
              <TextField
                id="averageFleetEfficiencyJPerTh"
                label={"Average efficiency of installed fleet"}
                placeholder="5"
                {...register("averageFleetEfficiencyJPerTh")}
                fullWidth
                type="number"
                variant="outlined"
                error={!!errors.averageFleetEfficiencyJPerTh}
                helperText={
                  errors.averageFleetEfficiencyJPerTh && <Box>{errors.averageFleetEfficiencyJPerTh.message}</Box>
                }
                onWheel={(evt) => {
                  (evt.target as HTMLElement).blur(); // disable edit by scroll
                }}
                InputProps={{
                  inputProps: { min: 0 },
                  endAdornment: <InputAdornment position="end">J/TH</InputAdornment>,
                }}
              />
            </Grid>
            <Grid container item md>
              <Grid container item xs={6} md={6}>
                <Box width="100%" height={150} maxHeight={150}>
                  <MuiFileDragAndDropUploader
                    text="Please upload a list of your machines"
                    onFileUpload={(files: File[]) => dispatch({ kind: "add", section: "machine-list", files })}
                  />
                </Box>
              </Grid>
              <Grid item xs={5} md={5} ml={1}>
                <DisplayFileList
                  id={"machineListFile"}
                  onDelete={(id: string) => dispatch({ kind: "remove", section: "machine-list", id })}
                  files={filesState.files["machine-list"]}
                ></DisplayFileList>
              </Grid>
            </Grid>
          </UploadGridRow>

          <UploadGridRow>
            <Grid item xs={12} md={6}>
              <TextField
                id="averageAsicPurchasePriceUsdPerTh"
                label={"[Optional] ASIC purchase price"}
                placeholder="5"
                {...register("averageAsicPurchasePriceUsdPerTh")}
                fullWidth
                type="number"
                variant="outlined"
                error={!!errors.averageAsicPurchasePriceUsdPerTh}
                helperText={
                  errors.averageAsicPurchasePriceUsdPerTh && (
                    <Box>{errors.averageAsicPurchasePriceUsdPerTh.message}</Box>
                  )
                }
                onWheel={(evt) => {
                  (evt.target as HTMLElement).blur(); // disable edit by scroll
                }}
                InputProps={{
                  inputProps: { min: 0 },
                  endAdornment: <InputAdornment position="end">USD/TH</InputAdornment>,
                }}
              />
              <FormHelperText>Average ASIC purchase price of existing fleet.</FormHelperText>
            </Grid>
            <Grid container item md>
              <Grid container item xs={6} md={6}>
                <Box width="100%" height={150} maxHeight={150}>
                  <MuiFileDragAndDropUploader
                    text={"Please upload invoices for your machines"}
                    onFileUpload={(files: File[]) => dispatch({ kind: "add", section: "invoices", files })}
                  />
                </Box>
              </Grid>
              <Grid item xs={5} md={5} ml={1}>
                <DisplayFileList
                  id={"machineInvoiceFiles"}
                  onDelete={(id: string) => dispatch({ kind: "remove", section: "invoices", id })}
                  files={filesState.files["invoices"]}
                ></DisplayFileList>
              </Grid>
            </Grid>
          </UploadGridRow>

          <UploadGridRow>
            <Grid item xs={12} md={6}>
              <TextField
                id="asicUptimePercentage"
                label={"Average ASIC up-time level in the past 12 months"}
                placeholder="95"
                {...register("asicUptimePercentage")}
                fullWidth
                type="number"
                variant="outlined"
                error={!!errors.asicUptimePercentage}
                helperText={errors.asicUptimePercentage && <Box>{errors.asicUptimePercentage.message}</Box>}
                sx={{
                  "& .MuiFormLabel-root": { whiteSpace: "break-spaces" },
                  "& .MuiInputBase-label": {
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  },
                }}
                onWheel={(evt) => {
                  (evt.target as HTMLElement).blur(); // disable edit by scroll
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
              />
            </Grid>
            <Grid container item md>
              <Grid container item xs={6} md={6}>
                <Box width="100%" height={150} maxHeight={150}>
                  <MuiFileDragAndDropUploader
                    text={"Please upload your miner uptime stats"}
                    onFileUpload={(files: File[]) => dispatch({ kind: "add", section: "uptime", files })}
                  />
                </Box>
              </Grid>
              <Grid item xs={5} md={5} ml={1}>
                <DisplayFileList
                  id={"minerUptimeStatsFiles"}
                  onDelete={(id: string) => dispatch({ kind: "remove", section: "uptime", id })}
                  files={filesState.files["uptime"]}
                ></DisplayFileList>
              </Grid>
            </Grid>
          </UploadGridRow>
          <UploadGridRow>
            <Grid container item xs={12} md={12}>
              <Grid item xs={12} md={6}>
                <TextField
                  id="capacityEncumbrancePercentage"
                  label={"Capacity encumbrance"}
                  placeholder="65"
                  {...register("capacityEncumbrancePercentage")}
                  fullWidth
                  type="number"
                  variant="outlined"
                  error={!!errors.capacityEncumbrancePercentage}
                  helperText={
                    errors.capacityEncumbrancePercentage && <Box>{errors.capacityEncumbrancePercentage.message}</Box>
                  }
                  onWheel={(evt) => {
                    (evt.target as HTMLElement).blur(); // disable edit by scroll
                  }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                />
                <FormHelperText>
                  Please state the % of assets that already hold an external claim (e.g., due to securitization of
                  loans).
                </FormHelperText>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl component="fieldset">
                <FormControlLabel
                  control={<Switch color="primary" {...register("hasInsurance")} checked={hasInsurance} />}
                  label="Do you have fire and property insurance?"
                  labelPlacement="end"
                />
              </FormControl>
            </Grid>
            {hasInsurance && (
              <Grid item xs={12} md={6}>
                <TextField
                  id="insuranceDescription"
                  label={"[Optional] Description of Fire & Property insurance"}
                  {...register("insuranceDescription")}
                  fullWidth
                  type="number"
                  variant="outlined"
                  multiline
                  rows={5}
                  error={!!errors.insuranceDescription}
                  onWheel={(evt) => {
                    (evt.target as HTMLElement).blur(); // disable edit by scroll
                  }}
                />
                <FormHelperText>
                  Please describe applicable site-level insurance (e.g. type of insurance, covered amount).
                </FormHelperText>
              </Grid>
            )}
          </UploadGridRow>

          <Box mt={2} gap={2} display="flex" alignItems={"center"} justifyContent={"flex-end"}>
            <LoadingButton
              type="submit"
              loading={isSavingAsic}
              disabled={!isDirty && !filesState.hasUpdates}
              variant="contained"
              sx={{ height: 40 }}
            >
              Save
            </LoadingButton>
          </Box>
        </Box>
      </form>
    </Box>
  );
};
